// Colors
@use "sass:math";

$white: #fff;

$text: #2d2d2d;
$text-lighten: #c0bdd0;
$text-darken: #615d7c;
$text-darken-more: #514d6a;
$text-dark-blue: #2e384d;
$link-color: #2e5bff;
$gray: #d2d9e5;
$gray-lighten: #eef0f4;
$gray-lighten-more: #eee;
$gray-lighten-more-more: #f9fafc;
$gray-darken: #b8beca;
$gray-border: #e4e9f0;
$gray-box: #e8eaf0;
$gray-darken-more: #999;
$gray-filter: #f2f2f2;
$black: #2f2f2f;
$black-lighten: #222034;
$black-lighten-more: #393749;

$blue: #00b0f4;
$blue-darken: #4a8fe7;
$orange: #f2a654;
$yellow: yellow;
$black-solid: black;

$menuBg: #2f2f2f;
$menuBg-darken: #2f2f2f;

$purple: #7c74ed;
$purple-light: #f0f0fc;
$purple-lighter: #e9edfc;

$green: #49c4b5;
$green-light: #cfeef6;
$yellow-light: #f4eede;

// Surfside Main Colours
$surf-black: #1e1e1e;
$surf-purple: #7b4af4;
$surf-magnolia: #f0f0fc;

// Surfside Secondary Colours
$surf-mid-gray: #4F4F4F;
$surf-light-gray: #E5E5E5;

// Surfside Accent Colours
$surf-indigo: #331c7a;
$surf-ultramarine: #1f69ff;
$surf-aquamarine: #49c4b5;
$surf-electriclime: #c4f70b;
$surf-crayola: #ffa81d;
$surf-orange: #ff6f1f;
$surf-red: #f22525;

// Accent colors
$default: #acb7bf;
$secondary: #6a7a84;
$warning: #f39834;
$danger: #fb434a;
$primary: #7b4af4;
$info: #331c7a;
$success: #49c4b5;
$error: #b48cc8;
$highlight: #b48cc8;
$normal: #e4e9f0;
$titleFont: #336;
$subtitleFont: #8798ad;
$filterColor: #30363d;
// Font Family
$base__font-family: 'Euclid Circular A Light', sans-serif !important;
$fontRubik: 'Euclid Circular A Light', sans-serif !important;
$fontAvenir: 'Euclid Circular A', sans-serif;
$fontEuclidMedium: 'Euclid Circular A', sans-serif !important;
$fontEuclidLight: 'Euclid Circular A Light', sans-serif !important;


// Font Size
$base__font-size: 13 !default;

// Convert value rem() sass mixin
@function rem($px, $base: $base__font-size) {
  @return #{floor(math.div($px, $base) * 100) * 0.01}rem; // to REMs
  // comment code above and uncomment below for change REMs to PXs
  //@return #{$px}px;
}

@keyframes spin {
  0%,
  100% {
    transform: translate(0);
  }
  25% {
    transform: translate(160%);
  }
  50% {
    transform: translate(160%, 160%);
  }
  75% {
    transform: translate(0, 160%);
  }
}

// Transitions
@mixin transition-fast() {
  transition: all 0.05s ease-in-out;
}
@mixin transition-middle() {
  transition: all 0.1s ease-in-out;
}
@mixin transition-slow() {
  transition: all 0.2s ease-in-out;
}
@mixin transition-very-slow() {
  transition: all 0.4s ease-in-out;
}
@mixin transition-color() {
  transition: color 0.2s ease-in-out;
}
@mixin preloader() {
  animation: spin 2s ease infinite;
}

// Responsive utils
$xxl-min-width: 1600px;
$xxl-max-width: 1599px;
$xl-min-width: 1200px;
$xl-max-width: 1199px;
$md-min-width: 992px;
$md-max-width: 991px;
$sm-min-width: 768px;
$sm-max-width: 767px;
$xs-min-width: 576px;
$xs-max-width: 575px;

:export {
  white: $white;

  text: $text;
  text-lighten: $text-lighten;
  text-darken: $text-darken;
  text-darken-more: $text-darken-more;
  text-dark-blue: $text-dark-blue;
  link-color: $link-color;
  gray: $gray;
  gray-lighten: $gray-lighten;
  gray-lighten-more: $gray-lighten-more;
  gray-lighten-more-more: $gray-lighten-more-more;
  gray-darken: $gray-darken;
  gray-border: $gray-border;
  gray-box: $gray-box;
  gray-darken-more: $gray-darken-more;
  gray-filter: $gray-filter;
  black: $black;
  black-lighten: $black-lighten;
  black-lighten-more: $black-lighten-more;

  blue: $blue;
  blue-darken: $blue-darken;
  orange: $orange;
  yellow: $yellow;
  black-solid: $black-solid;

  menuBg: $menuBg;
  menuBg-darken: $menuBg-darken;

  purple: $purple;
  purple-light: $purple-light;
  purple-lighter: $purple-lighter;

  green: $green;
  green-light: $green-light;
  yellow-light: $yellow-light;

  // Surfside Main Colours
  surf-black: $surf-black;
  surf-purple: $surf-purple;
  surf-magnolia: $surf-magnolia;

  // Surfside Secondary Colours
  surf-mid-gray: $surf-mid-gray;
  surf-light-gray: $surf-light-gray;

  // Surfside Accent Colours
  surf-indigo: $surf-indigo;
  surf-ultramarine: $surf-ultramarine;
  surf-aquamarine: $surf-aquamarine;
  surf-electriclime: $surf-electriclime;
  surf-crayola: $surf-crayola;
  surf-orange: $surf-orange;
  surf-red: $surf-red;

  // Accent colors
  default: $default;
  secondary: $secondary;
  warning: $warning;
  danger: $danger;
  primary: $primary;
  info: $info;
  success: $success;
  error: $error;
  highlight: $highlight;
  normal: $normal;
  titleFont: $titleFont;
  subtitleFont: $subtitleFont;
  filterColor: $filterColor;
}
