@import 'assets/styles/mixins.scss';

.platform {
  padding-top: 8px;
  font-family: 'Euclid Circular A';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  /* identical to box height, or 233% */

  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
}

//.wrapperLogo {
//  width: 102px;
//}

//.logo {
//  width: 130%;
//}

