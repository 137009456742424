// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN FORM */

.ant-form input[type='file'] {
  min-height: 40px;
}

.ant-form input.ant-input-sm[type='file'] {
  min-height: 48px;
}

.ant-form input.ant-input-lg[type='file'] {
  min-height: 52px;
}

.ant-form-item-label {
  text-align: left;
  white-space: normal;

  label {
    &:before {
      color: $surf-black !important;
    }
  } 
}

.ant-form-item > .ant-form-item,
.ant-form-item :not(.ant-form) > .ant-form-item {
  margin-top: -2px;
}

form .ant-select:only-child,
form .ant-cascader-picker:only-child {
  display: inline-block;
}

.ant-switch-checked {
  background-color: $primary;
}

.ant-picker, .ant-select, .ant-select:not(.ant-select-disabled):hover .ant-select-selector, .ant-input, 
.ant-tag, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover
 {
  &:hover {
    border-color: $primary;
  }
  &:focus {
    outline: none !important;
    border-color: $primary;
    -webkit-box-shadow: 0 0 0 2px rgba(123, 74, 244, .2);
    box-shadow: 0 0 0 2px rgba(123, 74, 244, .2);
  }
}

.ant-input-affix-wrapper-focused, .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) 
.ant-select-selector {
    outline: none !important;
    border-color: $primary;
    -webkit-box-shadow: 0 0 0 2px rgba(123, 74, 244, .2);
    box-shadow: 0 0 0 2px rgba(123, 74, 244, .2);
}
