/* @font-face {
    font-family: 'Rubik';
    src: url('Rubik-Light.eot');
    src: url('Rubik-Light.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Light.woff2') format('woff2'),
        url('Rubik-Light.woff') format('woff'),
        url('Rubik-Light.ttf') format('truetype'),
        url('Rubik-Light.svg#Rubik-Light') format('svg');
    font-weight: 300;
    font-style: normal;
} */

@font-face {
    font-family: 'Avenir Next Demi';
    src: url('AvenirNext-DemiBold.eot');
    src: url('AvenirNext-DemiBold.eot?#iefix') format('embedded-opentype'),
        url('AvenirNext-DemiBold.woff2') format('woff2'),
        url('AvenirNext-DemiBold.woff') format('woff'),
        url('AvenirNext-DemiBold.ttf') format('truetype'),
        url('AvenirNext-DemiBold.svg#AvenirNext-DemiBold') format('svg');
    font-weight: 600;
    font-style: normal;
}

/* @font-face {
    font-family: 'Rubik';
    src: url('Rubik-Medium.eot');
    src: url('Rubik-Medium.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Medium.woff2') format('woff2'),
        url('Rubik-Medium.woff') format('woff'),
        url('Rubik-Medium.ttf') format('truetype'),
        url('Rubik-Medium.svg#Rubik-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Bold.eot');
    src: url('Rubik-Bold.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Bold.woff2') format('woff2'),
        url('Rubik-Bold.woff') format('woff'),
        url('Rubik-Bold.ttf') format('truetype'),
        url('Rubik-Bold.svg#Rubik-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Rubik';
    src: url('Rubik-Regular.eot');
    src: url('Rubik-Regular.eot?#iefix') format('embedded-opentype'),
        url('Rubik-Regular.woff2') format('woff2'),
        url('Rubik-Regular.woff') format('woff'),
        url('Rubik-Regular.ttf') format('truetype'),
        url('Rubik-Regular.svg#Rubik-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
} */

