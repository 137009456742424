@import 'assets/fonts/custom/stylesheet.css';
@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/Antd/antd.cleanui';
@import 'assets/styles/CleanUI/cleanui';
@import 'assets/styles/Bootstrap/bootstrap.cleanui';
@import 'assets/styles/Nprogress/nprogress.cleanui';
@import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
//@import 'assets/font-awesome/css/all.css';

//@import '~c3/c3.min.css';


html {
  font-size: 13px;
  overflow-y: inherit;
}

.ant-layout-content {
  padding-top: 63px;
}

.ant-layout-header {
  position: fixed;
  z-index: 9999;
  width: 100%;
}
/*
.defaultSelect {
  width: 100%;
}
*/

.full-width {
  width: 100%;
}

.formGroup {
  margin-bottom: 15px;

  .ant-input-number {
    width: 100%;
  }
  .react-tagsinput {
    padding: 0;
    height: 30px;
    min-height: 30px;
    line-height: 1;
  }
  label {
    margin-bottom: 5px;
    font-weight: 500;
  }
}



// Buttons
.ant-btn-primary {
  align-items: center;
  background: $surf-black;
  border: 1px $surf-black;
  border-radius: 4px;
}
