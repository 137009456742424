// Import mixins
@import 'assets/styles/mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN PROGRESS */

.ant-progress-text {
  white-space: nowrap;
}

.ant-progress-status-success {
  .ant-progress-bg {
    background-color: $success;
  }
  .ant-progress-text {
    color: $success;
  }
}

.ant-progress-status-warning {
  .ant-progress-bg {
    background-color: $primary;
  }
  .ant-progress-text {
    color: $primary;
  }
}
