@import 'assets/styles/mixins.scss';

.topbar {
  background: $white;
  padding: 0 rem(20);
  min-height: 64px;
  height: 64px;
  border-bottom: 1px solid $gray-border;
  color: $text;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  z-index: 9999;
}

.isCollapsed {
  width: calc(100% - 170px);
}

.toRight {
  text-align: right;
}

.logoLink {
  //width: 102px;
  //display: block;
}

:global {
  body .ant-layout-header.layoutHeader {
    background: white;
    width: calc(100% - 80px);

    @media (max-width: $sm-max-width) {
      width: 100%
    }
  }
}
