@import 'assets/styles/mixins.scss';

.menu {
  box-sizing: content-box;
  position: relative;
  font-family: 'Questrial', sans-serif ;

  &:global(.ant-layout-sider-collapsed) {
    .navigation {
      :global(.ant-menu-submenu-title) {
        > span {
          justify-content: center;
        }
      }
    }

    :global(.ant-layout-sider-children) {
      width: 80px;
    }
  }


  :global(.ant-menu-light) {
    &:before,
    &:after {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      background: $gray-darken;
      opacity: 0.4;
      position: absolute;
      top: 0;
      left: 0;
    }

    &:after {
      left: auto;
      right: 0;
    }

    :global(.settings__menuShadow) & {
      &:before,
      &:after {
        display: none;
      }
    }

    @media (max-width: 1435px) {
      :global(.settings__fixedWidth) & {
        &:before {
          display: none;
        }
      }
    }
  }

  :global(.ant-layout-sider-children) {
    position: fixed;
    height: 100%;
    width: 256px;
    transition: width 0.1s;
    background: $surf-black;

    div > div {
      overflow: scroll;
    }
  }

  :global(.ant-menu-inline-collapsed-tooltip) .icon {
    display: none;
  }

  :global(.ant-layout-sider-zero-width-trigger) {
    top: 10px !important;
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    transition: padding 0s !important;
  }
}


.logo {
  height: 64px;
  background: transparent;
  overflow: hidden;
}

.logoContainer {
  height: 64px;
  padding: 13px 20px 15px 17px;
  img {
    height: 36px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.icon {
  text-align: center;
  right: 20px;
  width: 20px;
  top: px;
  margin: 0 !important;
  line-height: 1 !important;
  color: $gray-darken;
}

.navigation {
  padding: rem(15) 0 rem(15) !important;
  border: none;
  height: calc(100% - 66px);
  display: flex;
  flex-direction: column;

  @media (max-width: $sm-min-width) {
    height: 100%;
  }

  > :global(.ant-menu-item-divider) {
    margin: 0 23px !important;
  }

  :global(.ant-menu-submenu-inline) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
    right: 50px;
  }

  :global(.ant-menu-inline, .ant-menu-vertical),
  :global(.ant-menu-vertical-left) {
    border-right: none;
  }

  :global(.ant-menu-dark .ant-menu-inline.ant-menu-sub) {
    background: $menuBg !important;
    box-shadow: none !important;
    transition: all 0.15s;
  }

  &:global(.ant-menu-inline-collapsed) {
    .title {
      display: none;
    }

    :global(.ant-menu-submenu) > :global(.ant-menu-submenu-title .ant-menu-submenu-arrow) {
      display: block;
      opacity: 0.2;
      top: 48%;
      right: 8px;
    }

    .icon {
      right: 30px;
    }
  }

  &:global(.ant-menu-light) {
    .icon {
      color: $gray-darken;
      @include transition-slow();
    }

    :global(.ant-menu-submenu-arrow) {
      opacity: 0.4;
    }

    :global(.ant-menu-item:after) {
      right: auto;
      left: 0;
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu) {
      &:hover {
        .icon {
          color: $text;
        }
      }
    }

    :global(.ant-menu-item),
    :global(.ant-menu-submenu-title) {
      width: 100%;
    }
  }

  &:global(.ant-menu-dark) {
    color: $white;
    background: $surf-black;

    :global(.ant-menu-item-divider) {
      opacity: 0.2;
    }

    :global(.ant-menu-sub) {
      color: $gray-darken;
    }

    :global(.ant-menu-item) {
      color: $white;
      display: flex;
      align-items: center;
      > a {

        color: $white;
        &:hover {
          .icon {
            color: $white !important;
          }
        }
      }

      &:global(.ant-menu-item-selected) {
        background: rgba(0, 255, 198, 0.1);
      }
    }

    :global(.ant-menu-item-selected) {
      > a {
        color: #00ffc6;

        .icon {
          color: $primary !important;
        }
      }
    }

    :global(.ant-menu-submenu:hover),
    :global(.ant-menu-submenu-selected),
    :global(.ant-menu-submenu-open) {
      .icon {
        color: $white !important;
      }
    }

    :global(.ant-menu-submenu-active) {
      .icon {
        color: $blue !important;
      }
    }

    :global(.ant-menu-inline.ant-menu-sub) {
      background: #2f2f2f !important;
      box-shadow: none !important;
      transition: all 0.15s;
    }

    :global(.ant-menu-item:after) {
      border-right: 3px solid #00ffc6 !important;
      right: auto;
      left: 0;
    }
  }

  :global(.ant-menu-item),
  :global(.ant-menu-submenu-title) {
    font-size: 15px !important;

    // svg {
    //   position: fixed;
    //   left: 24px;
    // }

    svg + span {
      //margin-left: 45px;
      margin-left: 20px;
    }
  }

  :global(.ant-menu-submenu):nth-child(11) {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  :global(.ant-menu-item.ant-menu-item-only-child) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  :global(.ant-menu-submenu-title) {
    > span {
      display: flex;
      align-items: center;
    }
  }

  :global(.ant-menu-item) > a,
  :global(.ant-menu-submenu-title) {
    display: flex;
    align-items: center;
  }
}

.scrollbarMobile {
  height: calc(100vh - 67px) !important;
}

.light {
  background: $white;

  :global(.ant-layout-sider-trigger) {
    color: inherit;
    background: #2d2d2d;
  }

  :global(.ant-menu-item) > :global(a) {
    color: $text;
    &:hover {
      color: $blue;
    }
  }

  :global(.drawer-content) {
    background: $white !important;
  }
}

.menu {
  :global(.ant-menu-inline-collapsed-tooltip) {
    :global(.icon-collapsed-hidden) {
      display: none;
    }
  }

  :global(.ant-menu-inline-collapsed > .ant-menu-item) {
    padding: 0 calc(50% - 32px/2);
  }

  :global(.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title) {
    padding: 0 calc(50% - 32px / 2);
  }

  :global(.ant-menu-inline-collapsed) {
    :global(.badge-collapsed-hidden) {
      display: none;
    }
  }
}

.buyPro {
  padding: rem(20);
  background: #e4e9f0;
  border-radius: 3px;
  text-align: center;
  margin: 0 rem(20) rem(20);
  overflow: hidden;

  :global(.ant-menu-inline-collapsed) + .buyPro {
    display: none;
  }
}


.hamburger {
  border: 0;
  background: transparent;
  padding: 5px;
  margin: 17px 17px 17px 17px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flexWrapper {
  display: flex;
  align-items: center;
}

:global(.ant-tooltip) {
  svg {
    display: none;
  }
}

:global {
  .ant-menu-sub {
    padding-left: 0 !important;
    li.ant-menu-item {
      padding-left: 76px !important;
    }
    .ant-menu-submenu-title {
      padding-left: 76px !important;
    }
  }
  .ant-menu-submenu-popup {
    .ant-menu-sub {
      li.ant-menu-item {
        padding-left: 10px !important;
      }
      .ant-menu-submenu-title {
        padding-left: 10px !important;
      }
    }
  }
  .ant-menu-dark.ant-menu-submenu-popup {
    z-index:9999;
  }
}






